/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { translate } from '../../helpers/getters';
import { GlobalContext } from '../../context/MainProvider';
import { setCookie, getCookie } from '../../helpers/cookies';
import Button from '../button';
// import CookieModal from '../../../../components/molecules/cookie-modal';
import styles from './cookie-consent.module.scss';

const CookieConsent = ({
  acceptText = 'Accept',
  declineText = 'Decline',
  settingsCookie,
  cookieName = 'CookieConsent',
  logo = '../../../../../images/logo.svg',
  icon = null,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { state } = useContext(GlobalContext); 
  const { lang, translations } = state || '';

  const [showCookieConsent, setShowCookieConsent] = useState(false);

  // when user declines
  const handleDecline = () => {
    setCookie(cookieName, false, 365, '/');
    setCookie('showCookie', false, 365, '/');
    setShowCookieConsent(false);
    // setShowModal(false);
    document.body.style.overflow = 'auto';
  };

  // when user accepts
  const handleAccept = () => {
    setCookie(cookieName, true, 365, '/');
    setCookie('showCookie', false, 365, '/');
    setShowCookieConsent(false);
    // setShowModal(false);
    document.body.style.overflow = 'auto';
  };

  const handleShowModalClick = () => {
    setShowModal(!showModal);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setShowModal(false);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    if (typeof window !== `undefined` && getCookie('showCookie') !== 'false') {
      setShowCookieConsent(true);
    }
  }, []);

  return (
    <>
      <div className={`${styles.cookieConsent} ${(showCookieConsent && styles.show) || ''}`}>
        <Container className={`${styles?.consent || ''}`}>
          <div className={styles?.content || ''}>
            <p>
              
              {translate(translations, 'cookie_text', 'Cookie Setting')}
              {/* <a href="/cookies" className="cookie-consent-gtm">
                Cookie Policy
              </a> */}
              .
            </p>
          </div>
          <div className={styles?.buttonsContainer || ''}>
            {settingsCookie && (
              <button
                onClick={handleShowModalClick}
                className="cookie-consent-gtm btn-cta"
                type="button"
              >
                {translate(translations, 'cookie_setting_button', 'Cookie Setting')}
                {icon && icon}
              </button>
            )}
            
            <Button 
              onClick={() => handleAccept()}
              btnText={translate(translations, 'cookie_accept_button', acceptText)}
            />
            <Button 
              onClick={() => handleDecline()}
              btnText={translate(translations, 'cookie_decline_button', declineText)}
              buttonType='secondary'
            />
          </div>
        </Container>
      </div>
      {/* {settingsCookie && showModal && (
        <CookieModal
          logo={logo}
          hide={!showModal}
          handleAcceptCookies={handleAccept}
          handleDeclineCookies={handleDecline}
          closeModal={closeModal}
        />
      )} */}
    </>
  );
};

CookieConsent.propTypes = {
  acceptText: PropTypes.string,
  settingsCookie: PropTypes.bool,
  cookieName: PropTypes.string,
  logo: PropTypes.string,
  icon: PropTypes.node,
};

export default CookieConsent;