import React from 'react';

function PageNotFound() {  

  return (
    <div className="home">
      PageNotFound
    </div>
  );
}

export default PageNotFound