import React, { useContext} from 'react';
import { GlobalContext } from '../../../context/MainProvider';
import { translate } from '../../../helpers/getters';
import styles from './footer.module.scss';

function Footer() {  
  const { state } = useContext(GlobalContext); 
  const { translations } = state || '';

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <p className={styles.copyright}>
          {`
            © ${new Date().getFullYear()} 
            Vegan Ceylon. 
            ${translate(translations, 'all_rights_reserved', 'All rights reserved.')}
          `}
        </p>
      </div>
    </footer>
  );
}

export default Footer