/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './scroll-to-top.module.scss';

const ScrollToTop = ({ scrollTo = '0' }) => {
  const scrollToTopBtn = useRef();

  const scrollTop = () => {
    window.scrollTo({ top: scrollTo, behavior: 'smooth' });
  };

  return (
    <button
      type="button"
      aria-label="Scroll to top"
      onClick={(e) => scrollTop(e)}
      ref={scrollToTopBtn}
      className={styles.scrollToTop}
    />
  );
};

ScrollToTop.propTypes = {
  scrollTo: PropTypes.string,
};

export default ScrollToTop;
