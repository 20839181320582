import React from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from '../scroll-to-top';
import CookieConsent from '../cookie-consent';
import isSticky from '../../helpers/stickyOnScroll';
import styles from './floating-area.module.scss';

export default function FloatingArea({
  offsetTop = 400,
}) {
  const showScroll = isSticky(offsetTop);

  return (
    <div className={`${styles.floatingArea} ${showScroll ? styles.show : ''}`}>
      <ScrollToTop />
      {CookieConsent && <CookieConsent />}
    </div>
  );
}

FloatingArea.propTypes = {
  pageContext: PropTypes.shape({
    allMarkets: PropTypes.shape({}),
    page: PropTypes.shape({
      id: PropTypes.number,
      relation_type: PropTypes.string,
      path: PropTypes.string,
      template: PropTypes.string,
      relation: PropTypes.shape({
        status: PropTypes.string,
        logo_url: PropTypes.string,
      }),
      market: PropTypes.string,
    }),
    marketSections: PropTypes.shape({
      footer_navigation: PropTypes.shape({}),
      popup: PropTypes.shape({
        modules: PropTypes.arrayOf({}),
      }),
    }),
  }).isRequired,
  offsetTop: PropTypes.number,
  hideOperatorBanner: PropTypes.bool,
  template: PropTypes.string,
};