const countReducer = (state, action) => {
    switch(action.type) {
        case 'CHANGE_LANG': 
            return {
                ...state,
                lang: action.data
            }
        case 'CHANGE_TRANSLATIONS':
            return {
                ...state,
                translations: action.data
            }
        default:
            return state;
    }
}

export default countReducer;