import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Offcanvas from 'react-bootstrap/Offcanvas';
import { GlobalContext } from '../../../context/MainProvider';
import styles from './header.module.scss';

function Header({ src, alt }) {  
  const { state, dispatch } = useContext(GlobalContext); 
  const { lang } = state || '';
  const [countries, setCountries] = useState([]);
  const [activeCountry, setActiveCountry] = useState([]);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const onClick = useCallback(() => setIsActive(!isActive), [isActive]);

  // https://github.com/risan/country-flag-emoji-json/blob/main/dist/images/US.svg

  useEffect(() => {    
    fetch('/data/countries.json')
      .then(resp => resp.json())
      .then(allCountries => {
        setCountries(allCountries);
        setActiveCountry(allCountries.find(country => country?.code === lang));
      })
  }, [lang]);

  useEffect(() => {    
    fetch('/data/translations.json')
      .then(resp => resp.json())
      .then(translations => {
        dispatch({
          type: 'CHANGE_TRANSLATIONS',
          data: translations[lang]
      })
      })
  }, [dispatch, lang]);

  // Close on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !dropdownRef.current.previousElementSibling.contains(event.target)
      ) {
        setIsActive(false);
      }
    };
    if (typeof window !== 'undefined') window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      if (typeof window !== 'undefined')
        window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClick]);
  
  return activeCountry && (
    <header className={styles.header}>
      <Navbar fixed='top' expand='xl'>
        <Container className={styles.container}>
          {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} /> */}
          <Navbar.Brand href='/' className={styles.logoContainer}>
            <img src={src} alt='logo' className={styles.logo} />
            <span>Vegan Ceylon</span>
          </Navbar.Brand>
          <div className={`${styles.dropdownContainer || ''}`}>
            <div
              className={`${styles.dropdown || ''} ${isActive ? styles.dropdownActive : ''}`}
              onClick={onClick}
            >
              <img
                width={24}
                height={24}
                src={`../images/${activeCountry?.code}.svg`}
                loading="eager"
                alt={activeCountry?.code}
              />
            </div>
            <div
              ref={dropdownRef}
              className={`${styles.dropdownOptions || ''} ${isActive ? styles.active : ''}`}
            >
              {countries.filter(country => country.code !== lang).map(country => (
                <button key={country.code} onClick={() => dispatch({
                    type: 'CHANGE_LANG',
                    data: country.code
                }, onClick())}>
                  <img
                    width={24}
                    height={24}
                    src={`../images/${country?.code}.svg`}
                    loading="eager"
                    alt={country?.code}
                  />
                </button>
              ))}
            </div>
          </div>
          {/* <NavDropdown
            title='Dropdown'
            id={`offcanvasNavbarDropdown-expand-sm`}
          >
            <NavDropdown.Item href='#action3'>Action</NavDropdown.Item>
            <NavDropdown.Item href='#action4'>
              Another action
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href='#action5'>
              Something else here
            </NavDropdown.Item>
          </NavDropdown> */}
          {/* <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement='start'
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                Offcanvas
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className='justify-content-end flex-grow-1 pe-3'>
                <Nav.Link href='#action1'>Home</Nav.Link>
                <Nav.Link href='#action2'>Link</Nav.Link>
                <NavDropdown
                  title='Dropdown'
                  id={`offcanvasNavbarDropdown-expand-sm`}
                >
                  <NavDropdown.Item href='#action3'>Action</NavDropdown.Item>
                  <NavDropdown.Item href='#action4'>
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href='#action5'>
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Form className='d-flex'>
                <Form.Control
                  type='search'
                  placeholder='Search'
                  className='me-2'
                  aria-label='Search'
                />
                <Button variant='outline-success'>Search</Button>
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas> */}
        </Container>
      </Navbar>
    </header>
  );
}

export default Header