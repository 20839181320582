/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import { debounce } from '../scroll';

export default function StickyOnScroll(stickyOffset = 0, callback, elementRef, debounceTime = 50) {
  const [sticky, setSticky] = useState(false);

  const handler = debounce(() => {
    if (typeof window !== 'undefined' && typeof stickyOffset === 'number') {
      const offset =
        elementRef?.current?.getBoundingClientRect()?.top + window.pageYOffset || 0 + stickyOffset;
      const isSticky = window.pageYOffset > offset;
      setSticky(isSticky);
      callback?.(isSticky);
    }
  }, debounceTime);

  useEffect(() => {
    handler();

    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [sticky]);

  return sticky;
}
